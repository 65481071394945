
export default {
  name: 'TabsFormDelivery',
  props: ['options', 'position'],
  emits: ['filterChange'],
  setup(props: any, {
    emit
  }: any) {
    const changePosition = (val: any) => {
      emit('filterChange', val)
    }
    return {
      changePosition
    }
  }
}
