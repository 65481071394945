import {
  ref, computed, watch, getCurrentInstance, onMounted
} from 'vue'
import { useStore } from 'vuex'
import { listItemsReceiptUseCase } from '@/domain/usecase'
import confirmationService from '@/plugins/ui/confirmationService'
import lodash from 'lodash'

const modules = 'deliveryItem'

export default {
  setup() {
    const app = getCurrentInstance()
    const { $toast, $confirm } = app!.appContext.config.globalProperties
    const store = useStore()
    const listItems = computed(() => store.state[modules].listItems)
    const listDiskon = computed(() => store.state[modules].listDiskon)
    const voucherId = ref('')
    const voucherIsTrue = ref(false)
    const isMiisi = computed(() => store.state[modules].isMiisi)

    const calculateGrandPrice = async () => {
      store.dispatch(`${modules}/calculateGrandPrice`)
    }

    const updateDiskonInListItems = (list: any) => {
      store.dispatch(`${modules}/setVoucherId`, voucherId.value)
      let total = 0
      const grandTotalTemp = listItems.value.reduce((x: any, item: any) => x + item.Harga, 0)
      listItems.value.forEach((element: any) => {
        total += list.IsMoney ? list.Potongan : ((list.Persen / 100) * element.Harga)
        // console.log('total', total)
        // total += element.Harga
      })
      // const discount = list.IsMoney ? list.Potongan : ((list.Persen / 100) * total)
      // const discount = total
      const discount = Math.min(list.MaxPotongan, total)
      // console.log('diskon', discount)
      store.dispatch(`${modules}/setAttrVoucher`, {
        VoucherCodeId: list.VoucherCodeId,
        VoucherHdId: list.VoucherHdId,
        VoucherKode: voucherId.value,
        // VoucherValue: Math.min(list.MaxPotongan, discount)
        VoucherValue: discount
      })
      // const completeListDiskon = list
      // if (completeListDiskon[0]) {
      //   completeListDiskon[0].Kode = voucherId.value
      // }
      /** Function untuk perhitungan ddiskon berdasarkan item */
      // store.dispatch(`${modules}/setListDiskon`, list)
      listItems.value.filter((item: any) => {
        // const test = list.filter((lis: any) => lis.PengirimanDetailId === item.Id)
        // if (test.length === 1) {
        // console.log('item', item)
        // console.log('value', listItems.value.length)
        // console.log('harga', item.Harga)
        item.DiskonValue = Math.round((item.Harga / grandTotalTemp) * store.state[modules].voucherAttr.voucherValue)
        // item.DiskonId = test[0].DiskonId
        return item
        // }
        // return false
      })
      // console.log('listItems', listItems)
      store.dispatch(`${modules}/calculateGrandPrice`)
    }

    const checkInputVoucher = () => {
      voucherId.value = store.state[modules].voucherId
      if (voucherId.value) {
        voucherIsTrue.value = true
      } else {
        voucherIsTrue.value = false
      }
    }

    const submitVoucher = async () => {
      store.dispatch('showLoading')
      const response = await listItemsReceiptUseCase.checkVoucher({
        Kode: voucherId.value,
        PengirimanHdId: store.state[modules].dataAfterPost.Id
      })
      if (!response.error) {
        updateDiskonInListItems(response.result)
        voucherIsTrue.value = true
      } else {
        voucherId.value = ''
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const cancelVoucher = () => {
      voucherId.value = ''
      voucherIsTrue.value = false
      store.dispatch(`${modules}/setVoucherId`, '')
      store.dispatch(`${modules}/setListDiskon`, [])
      store.dispatch(`${modules}/setListItem`, listItems.value.map((item: any) => {
        item.DiskonId = null
        item.DiskonValue = 0
        return item
      }))
      store.dispatch(`${modules}/setAttrVoucher`, {
        VoucherCodeId: null,
        VoucherHdId: null,
        VoucherKode: null,
        VoucherValue: 0
      })
      store.dispatch(`${modules}/calculateGrandPrice`)
    }

    watch(voucherId, (val) => {
      store.dispatch(`${modules}/setVoucherId`, val)
    })

    onMounted(() => {
      calculateGrandPrice()
      checkInputVoucher()
    })

    return {
      listItems,
      voucherId,
      voucherIsTrue,
      listDiskon,
      submitVoucher,
      cancelVoucher,
      lodash,
      isMiisi
    }
  }
}
