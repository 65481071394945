/* eslint-disable */
import { Response } from '@/domain/entities'
import {
  Remote,
  remoteEnum
} from '@/data/source/remote/remoteApi'
import RepositoryV2 from '@/data/repositoryV2'

class ApiUseCase {
  get(path: any): Promise<Response> {
    return new RepositoryV2(new Remote(remoteEnum.get, `${path}`), null).getResult(false)
  }

  postOrPut(path: any, id: any, data: any = null, param = ''): Promise<Response> {
    if (id) {
      return new RepositoryV2(new Remote(remoteEnum.put, `${path}/${id}${param}`, data), null).getResult(false)
    } return new RepositoryV2(new Remote(remoteEnum.post, `${path}`, data), null).getResult(false)
  }

  patch(path: any, id: any, data?: any): Promise<Response> {
    return new RepositoryV2(new Remote(remoteEnum.patch, `${path}/${id}`, data), null).getResult(false)
  }

  delete(path: any, id: any, data?: any): Promise<Response> {
    return new RepositoryV2(new Remote(remoteEnum.delete, `${path}/${id}`, data), null).getResult(false)
  }

  getUser(path: any, data: any): Promise<Response> {
    return new RepositoryV2(new Remote(remoteEnum.post, `${path}`, data), null).getResult(false)
  }
}

const apiUseCase = new ApiUseCase()

export default apiUseCase
