import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "tabs-form-delivery" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "container-tabs-filter w-full" }
const _hoisted_4 = {
  class: "mx-3 my-1 font-semibold text-base",
  style: {"white-space":"nowrap"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.options, (data) => {
          return (_openBlock(), _createBlock("div", {
            class: ["container-button", $props.position == data.value ? 'select-button' : 'select-button-null'],
            key: data.key,
            onClick: ($event: any) => ($setup.changePosition(data))
          }, [
            _createVNode("p", _hoisted_4, _toDisplayString(data.label), 1)
          ], 10, ["onClick"]))
        }), 128))
      ])
    ])
  ]))
}