import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  alt: "milsi-logo",
  src: "/img/milsi-logo.png",
  style: {"height":"30px"},
  class: "mb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Stepper = _resolveComponent("Stepper")
  const _component_DeliveryDestination = _resolveComponent("DeliveryDestination")
  const _component_SenderData = _resolveComponent("SenderData")
  const _component_DeliveryListItem = _resolveComponent("DeliveryListItem")
  const _component_DeliveryReceipt = _resolveComponent("DeliveryReceipt")
  const _component_Card = _resolveComponent("Card")

  return (_openBlock(), _createBlock("section", null, [
    _createVNode(_component_Card, null, {
      title: _withCtx(() => [
        (_ctx.isMiisi)
          ? (_openBlock(), _createBlock("img", _hoisted_1))
          : _createCommentVNode("", true),
        _createVNode(_component_Stepper, { currentIndex: _ctx.currentIndex }, null, 8, ["currentIndex"])
      ]),
      content: _withCtx(() => [
        (_ctx.currentIndex == 0)
          ? (_openBlock(), _createBlock(_component_DeliveryDestination, { key: 0 }))
          : (_ctx.currentIndex == 1)
            ? (_openBlock(), _createBlock(_component_SenderData, { key: 1 }))
            : (_ctx.currentIndex == 2)
              ? (_openBlock(), _createBlock(_component_DeliveryListItem, { key: 2 }))
              : (_ctx.currentIndex == 3)
                ? (_openBlock(), _createBlock(_component_DeliveryReceipt, { key: 3 }))
                : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}