import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "tabs-day" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TabView = _resolveComponent("TabView")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode(_component_TabView, {
      class: "color-primary",
      activeIndex: $setup.active,
      "onUpdate:activeIndex": _cache[1] || (_cache[1] = ($event: any) => ($setup.active = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, { header: "Hari Ini" }),
        _createVNode(_component_TabPanel, { header: "Besok" }),
        _createVNode(_component_TabPanel, { header: "Lusa" })
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ]))
}