import {
  ref, onMounted, getCurrentInstance, reactive, computed, watch
} from 'vue'
import { useStore } from 'vuex'
import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'
import { getListAgent } from '@/domain/usecase'
import { IMsAgen } from '@/utils/interfaces/iMsAgen'
import { debounce } from 'lodash'

const modules = 'deliveryItem'

export default {
  components: {
    VueEternalLoading
  },
  props: {
    isShowDialog: {
      default: () => false
    },
    title: {
      default: () => 'Pilih Agen Tujuan'
    },
    maxShow: {
      default: () => 40
    }
  },
  emits: ['hideDialog', 'sendOutData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $toast } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const toCityId = computed(() => propertiesProps.toCityId)
    const isInitial = ref(false) /** variable yang digunakan untuk mereset state menggunakan plugin vue-eternal-loading */
    const isShowDialog = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val) => {
        if (!val) {
          emit('hideDialog')
        }
      }
    })
    const title = computed(() => propertiesProps.title)
    const store = useStore()
    const selectedData = ref(null) as any
    const searchValue = ref('') as any
    let countRow = 0

    const hideDialog = () => {
      emit('hideDialog')
    }
    const isMatch = (dataString: string, matchString: string) => {
      if (matchString.length > 2) {
        if (dataString.toLowerCase().indexOf(matchString.toLowerCase()) !== -1) {
          countRow++
          if (countRow > 20) {
            return false
          }
        }
        return dataString.toLowerCase().indexOf(matchString.toLowerCase()) !== -1
      }
      if (dataString.toLowerCase().indexOf(matchString.toLowerCase()) !== -1) {
        countRow++
        if (countRow > 20) {
          return false
        }
      }
      return true
    }

    const selectAct = (item: any) => {
      if (item) {
        emit('sendOutData', item)
        hideDialog()
      }
      // if (item.Nama === searchValue.value) {
      //   selectedData.value = null
      //   searchValue.value = ''
      // } else {
      //   selectedData.value = item
      //   searchValue.value = item.Nama
      // }
    }
    const sendSelectedData = () => {
      if (selectedData.value === null) {
        $toast.add({
          severity: 'error', detail: 'Kota tujuan belum terpilih ', group: 'bc', life: 3000
        })
      } else {
        emit('sendOutData', selectedData.value)
        hideDialog()
      }
    }
    // const delay = (function () {
    //   let timer: any = 0
    //   return function (callback: any, ms: any, that: any) {
    //     clearTimeout(timer)
    //     timer = setTimeout(callback.bind(that), ms)
    //   }
    // })()
    const setStyleSheet = (val: any) => {
      if (val) {
        if (selectedData.value) {
          return val.Id === selectedData.value.Id ? 'background-color: rgb(255, 243, 224);' : ''
        }
      }
      return ''
    }

    const listAgent = ref<IMsAgen[]>([])
    const filters = reactive({
      skip: 0,
      top: 10,
      totalRecords: 0,
      custom: 'IsActive eq true',
      expand: '&$expand=TipeAgen($select=Tipe,Nama),Kota($select=Nama,Kode)',
      select: '&$select=Id,Alamat,KotaId,Nama,Kode,KodeResi',
      sorting: 'Kota/Nama asc',
      search: ''
    })
    const fetchAgents = async (isReset = false) => {
      const {
        error,
        result,
        count
      } = await getListAgent(filters)
      const mapResult: IMsAgen[] = result
      if (!error) {
        if (isReset) {
          listAgent.value = []
        }
        listAgent.value = [...listAgent.value, ...mapResult]
        filters.skip += filters.top
        filters.totalRecords = count
        return result
      } throw result
    }

    const loadData = async ({ loaded, noMore }: LoadAction) => {
      fetchAgents(isInitial.value)
        .then((res) => {
          if (res.length === 0 && listAgent.value.length === filters.totalRecords) {
            /** menggunakan fungsi unutuk mereset state data kosong type dri interface plugin vue-eternal-loading */
            noMore()
          } else {
            /** menggunakan fungsi unutuk load state type dri interface plugin vue-eternal-loading */
            loaded()
          }
        })
    }

    const onSearch = (search: string) => {
      filters.search = search
      filters.skip = 0
      fetchAgents(true)
    }

    watch(() => searchValue.value, debounce(onSearch, 500))

    onMounted(() => {
      if (store.state[modules].typeDelivery === 'AD' || store.state[modules].typeDelivery === 'DD') {
        console.log('dooring')
        filters.custom = `${filters.custom} and IsKurirDooring eq true`
      }
      fetchAgents()
    })
    return {
      toCityId,
      title,
      isShowDialog,
      store,
      hideDialog,
      selectedData,
      searchValue,
      isMatch,
      selectAct,
      sendSelectedData,
      setStyleSheet,
      isInitial,
      loadData,
      listAgent
    }
  }
}
