import {
  ref, onMounted, getCurrentInstance, reactive, computed, watch, onBeforeUnmount
} from 'vue'
import { getListRute, priceUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import jexcel from 'jspreadsheet-ce'
import { useRoute } from 'vue-router'

export default {
  props: {
    isShowDialog: {
      default: () => false
    },
    fromCityId: {
      default: () => 2
    },
    toCityId: {
      default: () => 24
    },
    title: {
      default: () => 'Cek Harga Pengiriman'
    }
  },
  emits: ['hideDialog'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $toast } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const fromCityId = computed(() => propertiesProps.fromCityId)
    const toCityId = computed(() => propertiesProps.toCityId)
    const isShowDialog = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val) => {
        if (!val) {
          emit('hideDialog')
        }
      }
    })
    const title = computed(() => propertiesProps.title)
    const indexTab = ref(0)
    const priceActive = ref({}) as any
    const store = useStore()
    const selectedCategory = ref({
      Id: null
    }) as any
    const filters = reactive({
      custom: `kotaasalid eq ${fromCityId.value} and kotatujuanid eq ${toCityId.value} or (kotaasalid eq ${toCityId.value} and kotatujuanid eq ${fromCityId.value})`,
      select: '&$select=HargaRetailId, HargaCompanyId, HargaRetail, HargaCompany',
      expand: '&$expand=hargaretail($select=id, isactive,kode;$expand=MsHargaKategoris($expand=kategori($select=id,kode,nama,isactive;$filter=iscompany eq false))),hargacompany($select=id, isactive,kode;$expand=MsHargaKategoris($expand=kategori($select=id,kode,nama,isactive;$filter=iscompany eq true)))'
    })
    const data = ref([]) as any
    const route = useRoute() as any
    const dataExcel = ref([
      {}
    ])
    const jExcelOptions = ref({
      data: dataExcel.value,
      hideIndex: true,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowDeleteRow: false,
      allowInsertRow: false,
      contextMenu: false,
      editable: false,
      columns: [
        {
          name: 'Min',
          title: 'Min',
          // type: 'number',
        },
        {
          name: 'Max',
          title: 'Max',
          // type: 'number',
        },
        {
          name: 'Volume',
          title: 'm3',
          // type: 'number',
        },
        {
          name: 'HargaDd',
          title: 'D-D',
          // type: 'number',
        },
        {
          name: 'HargaAa',
          title: 'A-D',
          // type: 'number',
        },
        {
          name: 'HargaDa',
          title: 'D-A',
          // type: 'number',
        },
        {
          name: 'HargaAa',
          title: 'A-A',
          // type: 'number',
        },
      ],
      nestedHeaders: [
        [
          {
            title: 'Berat',
            colspan: '2',
          },
          {
            title: 'Volume',
            colspan: '1',
          },
          {
            title: 'Jenis Pengiriman',
            colspan: '4',
          },
        ]
      ],
      columnSorting: true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onchange: (instance: any, cell: any, x: any, y: any, value: any) => { },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onselection: (instance: any, cell: any, x: any, y: any, value: any) => { }
    })
    const jExcelObj = ref(null) as any
    const showSubmitExcel = ref(false)

    const hideDialog = () => {
      emit('hideDialog')
    }

    const listClassMenu = (item: any) => {
      if (selectedCategory.value) {
        if (item.Id === selectedCategory.value.Id) {
          return 'color-primary px-3 py-1 border-orange mr-2 mb-2 cursor-pointer background-orange-lighten-5 rounded-md'
        }
        console.log('selectedCategory', selectedCategory.value.Id, item.Id)
      }

      return 'px-3 py-1 cursor-pointer background-blue-grey-lighten-5 mr-2 mb-2 rounded-md'
    }

    const onTableChange = (instance: any, cell: any, x: any, y: any, value: any) => {
      if (!showSubmitExcel.value) {
        showSubmitExcel.value = true
      }
      jExcelObj.value.ignoreEvents = true
      if (!Number.isNaN(Number(value))) {
        jExcelObj.value.setValue(cell, Number(value))
      } else {
        jExcelObj.value.setValue(cell, '')
      }
      jExcelObj.value.ignoreEvents = false
    }

    const getDataHargaDetail = async (item: any) => {
      const response = await priceUseCase.getDataHargaDetail({
        hargaHdId: Number(item.HargaId),
        kategoriId: item.KategoriId
      })

      if (!response.error) {
        dataExcel.value = response.result
        jExcelObj.value.setData((dataExcel.value.length > 0) ? dataExcel.value : [{}])
      } else {
        $toast.add({
          severity: 'error', detail: 'Gagal mengambil harga, silahkan coba kembali', group: 'bc', life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const initExcel = () => {
      const docs = document.getElementById('spreadsheet')
      jExcelOptions.value.onchange = onTableChange
      jExcelObj.value = jexcel(docs, jExcelOptions.value)
      jExcelObj.value.hideIndex()
      // jExcelObj.value.onchange = onTableChange
    }

    const changeItemsId = async () => {
      store.dispatch('showLoading')
      jExcelObj.value.destroy()
      initExcel()
      showSubmitExcel.value = false
      await getDataHargaDetail(selectedCategory.value)
      store.dispatch('hideLoading')
    }

    const initSelectedCategory = () => {
      if (priceActive.value) {
        if (priceActive.value.MsHargaKategoris.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          selectedCategory.value = priceActive.value.MsHargaKategoris[0]
          changeItemsId()
          return
        }
      }
      selectedCategory.value = {
        Id: null
      }
    }

    const setupPriceActive = (index: number) => {
      console.log('setupPriceActive', index)
      if (data.value.length > 0) {
        if (index === 0) {
          priceActive.value = data.value[0].HargaRetail
        } else {
          priceActive.value = data.value[0].HargaCompany
        }
      } else {
        priceActive.value = null
      }
      initSelectedCategory()
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      const response = await getListRute(filters)

      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.error, group: 'bc', life: 1500
        })
      } else {
        data.value = response.result
        setupPriceActive(indexTab.value)
      }
      store.dispatch('hideLoading')
    }

    const clickCategory = (item: any) => {
      console.log('clickCategory')
      selectedCategory.value = item
      changeItemsId()
    }

    watch(indexTab, (val: any) => {
      setupPriceActive(val)
    })

    onMounted(() => {
      getAllData()
      initExcel()
    })

    onBeforeUnmount(() => {
      jExcelObj.value.destroy()
    })
    return {
      fromCityId,
      toCityId,
      filters,
      title,
      isShowDialog,
      data,
      indexTab,
      priceActive,
      store,
      selectedCategory,
      jExcelObj,
      listClassMenu,
      hideDialog,
      clickCategory,
      getAllData
    }
  }
}
