import {
  ref, onMounted, getCurrentInstance, reactive, computed
} from 'vue'
import TabsFormDelivery from '@/views/transactions/delivery-item/create/steps-2/components/TabsFormDelivery.vue'
import { useStore } from 'vuex'
import FormSenderCompany from '@/views/transactions/delivery-item/create/steps-2/form/FormSenderCompany.vue'
import FormSenderRetail from '@/views/transactions/delivery-item/create/steps-2/form/FormSenderRetail.vue'
import FormSenderEtc from '@/views/transactions/delivery-item/create/steps-2/form/FormSenderEtc.vue'

const modules = 'deliveryItem'

export default {
  components: {
    TabsFormDelivery,
    FormSenderCompany,
    FormSenderRetail,
    FormSenderEtc
  },
  setup() {
    const store = useStore()
    const tabsOptions = ref([{
      label: 'Retail',
      value: 1
    },
    {
      label: 'Perusahaan',
      value: 2
    },
    {
      label: 'Pelanggan',
      value: 3
    }])
    const positionFilter = computed(() => store.state[modules].positionTabs)
    const isMiisi = computed(() => store.state[modules].isMiisi)
    const isFromQr = computed(() => store.state[modules].isFromQr)

    const ChangePosition = (val: any) => {
      if (val.value !== store.state[modules].positionTabs) {
        // console.log('change filter', val)
        store.dispatch(`${modules}/setDefaultFormState`)
        store.dispatch(`${modules}/changePostionTabs`, val.value)
      }
      // positionFilter.value = val.value
    }

    const submitData = () => {
      store.dispatch(`${modules}/setCurrentIndex`, 2)
    }

    const submitBack = () => {
      store.dispatch(`${modules}/setCurrentIndex`, 0)
    }

    onMounted(() => {
      // getOptionCompany()
    })

    return {
      tabsOptions,
      ChangePosition,
      positionFilter,
      submitData,
      store,
      submitBack,
      isMiisi,
      isFromQr
    }
  }
}
