import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "px-4" }
const _hoisted_2 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabsFormDelivery = _resolveComponent("TabsFormDelivery")
  const _component_FormSenderCompany = _resolveComponent("FormSenderCompany")
  const _component_FormSenderRetail = _resolveComponent("FormSenderRetail")
  const _component_FormSenderEtc = _resolveComponent("FormSenderEtc")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    (!_ctx.isMiisi && !_ctx.isFromQr)
      ? (_openBlock(), _createBlock(_component_TabsFormDelivery, {
          key: 0,
          options: _ctx.tabsOptions,
          onFilterChange: _ctx.ChangePosition,
          position: _ctx.positionFilter
        }, null, 8, ["options", "onFilterChange", "position"]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_2, [
      (_ctx.positionFilter == 2)
        ? (_openBlock(), _createBlock(_component_FormSenderCompany, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.positionFilter == 3)
        ? (_openBlock(), _createBlock(_component_FormSenderRetail, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.positionFilter == 1)
        ? (_openBlock(), _createBlock(_component_FormSenderEtc, { key: 2 }))
        : _createCommentVNode("", true)
    ])
  ]))
}