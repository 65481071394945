import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container-dialog-confirm" }
const _hoisted_2 = { class: "header mb-3" }
const _hoisted_3 = {
  key: 0,
  alt: "miisi-logo",
  src: "/img/milsi-logo.png",
  class: "",
  style: {"height":"25px"}
}
const _hoisted_4 = {
  key: 1,
  class: "content"
}
const _hoisted_5 = { class: "container-img" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "container-btn-action " }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")
  const _component_Dialog = _resolveComponent("Dialog")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Dialog, {
      showHeader: false,
      visible: _ctx.showModal,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModal = $event)),
      breakpoints: { '960px': '75vw', '640px': '90vw' },
      style: { width: '30vw' },
      modal: true,
      class: "wrap-content-dialog-confirm"
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          (_ctx.options.isMiisi)
            ? (_openBlock(), _createBlock("img", _hoisted_3))
            : _createCommentVNode("", true)
        ]),
        (_ctx.slots['content'])
          ? _renderSlot(_ctx.$slots, "content", { key: 0 })
          : (_openBlock(), _createBlock("div", _hoisted_4, [
              _createVNode("div", _hoisted_5, [
                _createVNode("img", {
                  src: _ctx.options.imgContent,
                  alt: "img-content"
                }, null, 8, ["src"])
              ]),
              _createVNode("div", _hoisted_6, _toDisplayString(_ctx.options.title), 1),
              _createVNode("div", {
                class: "sub-title",
                innerHTML: _ctx.options.subTitle
              }, null, 8, ["innerHTML"]),
              _createVNode("div", _hoisted_7, [
                _createVNode(_component_Button, {
                  type: "submit",
                  class: "btn-primary mb-2 md:mb-0 mx-auto",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmit()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.options.btnSubmitTitle), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Button, {
                  class: "btn-secondary mx-auto",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog())),
                  label: _ctx.options.btnBackTitle
                }, null, 8, ["label"])
              ])
            ]))
      ]),
      _: 3
    }, 8, ["visible"])
  ]))
}