import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'

const modules = 'deliveryItem'

export default {
  props: ['listStep'],
  emits: ['changeStep'],
  setup(props: any, { emit }: any) {
    const store = useStore()
    const currentIndex = computed(() => store.state[modules].currentIndex)
    const listStep = ref([
      {
        name: 'Tujuan Pengiriman'
      },
      {
        name: 'Data Pengiriman'
      },
      {
        name: 'Barang yang Dikirim'
      },
      {
        name: 'Cetak Resi'
      }
    ])

    const nextStep = () => {
      // currentIndex.value++
      emit('changeStep', currentIndex.value)
    }

    const prevStep = () => {
      // currentIndex.value--
      emit('changeStep', currentIndex.value)
    }

    const classStepActive = (index: number) => {
      if (currentIndex.value >= index) {
        return 'item-stepper grid auto-cols-auto grid-flow-col gap-1 active'
      }
      return 'item-stepper grid auto-cols-auto grid-flow-col gap-1'
    }

    onMounted(() => {
      // console.log('currentIndex', currentIndex.value)
    })

    return {
      currentIndex,
      listStep,
      nextStep,
      prevStep,
      classStepActive
    }
  }
}