
/* eslint-disable vue/no-dupe-keys */
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import {
  defineComponent,
  reactive,
  ref
} from 'vue'

interface IPropsConfirmDialog {
  imgContent: string
  title: string
  subTitle: string
  btnSubmitTitle: string
  btnBackTitle: string
  isMiisi?: boolean
}

export default defineComponent({
  name: 'DialogConfirm',
  components: {
    Dialog,
    Button
  },
  props: {
    imgContent: {
      default: () => require('@/assets/img/confirmation/package-return.png')
    },
    title: {
      type: String,
      default: 'Title'
    },
    subTitle: {
      type: String,
      default: 'Sub - Tile'
    },
    btnSubmitTitle: {
      type: String,
      default: 'Simpan Perubahan'
    },
    btnBackTitle: {
      type: String,
      default: 'Kembali'
    },
    isMiisi: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, slots }) {
    let options = reactive<IPropsConfirmDialog>({
      imgContent: props.imgContent,
      title: props.title,
      subTitle: props.subTitle,
      btnSubmitTitle: props.btnSubmitTitle,
      btnBackTitle: props.btnBackTitle,
      isMiisi: props.isMiisi
    })
    const showModal = ref(false)
    const hide = () => {
      showModal.value = false
    }
    const show = () => {
      showModal.value = true
    }
    let resolving: any = null
    const closeDialog = () => {
      hide()
      if (resolving) {
        resolving(false)
      }
    }
    const openModal = (attrOption: Partial<IPropsConfirmDialog> | null = null) => {
      if (attrOption) {
        options = Object.assign(options, attrOption)
      }
      show()
      return new Promise<boolean>((resolve) => {
        resolving = resolve
      })
    }

    const onSubmit = () => {
      resolving(true)
      hide()
    }
    return {
      showModal,
      closeDialog,
      onSubmit,
      openModal,
      options,
      slots,
      show,
      hide
    }
  }
})
