/* eslint-disable no-unused-expressions */
import AddOnReceipt from '@/views/transactions/delivery-item/create/steps-4/AddOnReceipt.vue'
import ListItemsReceipt from '@/views/transactions/delivery-item/create/steps-4/ListItemsReceipt.vue'
import {
  ref, getCurrentInstance, onMounted, watch, computed
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { paymentUseCase, deliveryReceiptUsecase, reportUseCase } from '@/domain/usecase'
import { fileWrite } from '@/plugins/system/filesystem'
import { isMobile } from '@/plugins/system/device'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import network from '@/plugins/system/network'
import DialogConfirm from '@/views/shared-components/dialog/Confirm.vue'

const modules = 'deliveryItem'

export default {
  components: {
    AddOnReceipt,
    ListItemsReceipt,
    DialogConfirm
  },
  setup() {
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $toast,
      $confirm,
      $swal
    } = app!.appContext.config.globalProperties
    const grandPrice = computed(() => store.state[modules].grandPrice)
    const descriptionAddOn = computed(() => store.state[modules].descriptionAddOn)
    const listPayment = ref([]) as any
    const isDownloadFile = ref(false)
    const showButton = ref(false)
    const addonReceiptEl = ref()
    const elListItemsReceipt = ref()
    const isOnline = ref(true)
    const showPaymentOptions = ref(true)
    const oldPaymentId = ref(null) as any
    const paymentId = computed({
      get: () => store.state[modules].paymentId,
      set: async (val: any) => {
        oldPaymentId.value = paymentId.value
        const data = { PengirimanHdId: store.state[modules].dataAfterPost.Id, JenisPembayaranId: val }
        if (paymentId.value !== null) {
          store.dispatch('showLoading')
          const res = await deliveryReceiptUsecase.updatePayment(data)
          if (res.error) {
            $toast.add({
              severity: 'error', detail: 'Jenis pembayaran gagal diubah, silahkan coba kembali', group: 'bc', life: 3000
            })
            // paymentId.value = oldPaymentId.value
            showPaymentOptions.value = false
            // paymentId.value = null
            store.dispatch(`${modules}/setPaymentId`, null)
            showPaymentOptions.value = true
            setInterval(() => { store.dispatch(`${modules}/setPaymentId`, oldPaymentId.value) }, 500)
            // store.dispatch(`${modules}/setPaymentId`, oldPaymentId.value)
            // window.location.reload()
            // console.log('state', store.state)
          } else {
            store.dispatch(`${modules}/setPaymentId`, val)
            elListItemsReceipt.value.cancelVoucher()
          }
          store.dispatch('hideLoading')
        }
        // store.dispatch(`${modules}/setPaymentId`, val)
      }
    })
    const isMiisi = computed(() => store.state[modules].isMiisi)
    const { typeDelivery } = store.state[modules]
    const paymentRef = ref('')
    const router = useRouter()
    const refDialogConfirm = ref<InstanceType<typeof DialogConfirm>>()

    const getTextTypeDelivery = () => {
      if (typeDelivery === 'AD') {
        return `${typeDelivery} (Agent To Door)`
      } if (typeDelivery === 'DA') {
        return `${typeDelivery} (Door To Agent)`
      } if (typeDelivery === 'DD') {
        return `${typeDelivery} (Door To Door)`
      } if (typeDelivery === 'AA') {
        return `${typeDelivery} (Agent To Agent)`
      }
      return 'Tidak Ditemukan'
    }

    const getListPayment = async () => {
      const response = await paymentUseCase.getAll()
      if (!response.error) {
        listPayment.value = await response.result
        // eslint-disable-next-line no-use-before-define
        // cashOvoPaymentOnly(response.result)
        if (paymentId.value === null) {
          // paymentId.value = listPayment.value[0].Id
          store.dispatch(`${modules}/setPaymentId`, listPayment.value[0].Id)
        }
      } else {
        $toast.add({
          severity: 'error', detail: 'Data pembayaran gagal diambil, silahkan coba kembali', group: 'bc', life: 3000
        })
      }
    }

    const cashOvoPaymentOnly = (val: any) => {
      // eslint-disable-next-line array-callback-return
      val.map((x: any) => {
        if (x.Kode === 'TUNAI' || x.Kode === 'OVO' || x.Kode === 'MANDIRI') {
          listPayment.value.push(x)
        }
        // listPayment.value.push(x)
      })
    }

    const onChangePayment = (val: any) => {
      store.dispatch(`${modules}/setPaymentId`, val.value)
    }

    const getGrandPrice = () => {
      store.dispatch(`${modules}/calculateGrandPrice`)
    }

    // converter base64
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

    const prosesSubmit = async () => {
      store.dispatch('showLoading')
      showButton.value = true
      if (grandPrice.value < 0) {
        $toast.add({
          severity: 'error', detail: 'Grand total tidak boleh minus', group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
        showButton.value = false
        return
      }
      if (!descriptionAddOn.value) {
        $toast.add({
          severity: 'error', detail: 'Keterangan Biaya/Pengakuan Harus diisi', group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
        showButton.value = false
        return
      }
      if (store.state[modules].listItems.length > 0 && store.state[modules].listItems[0].AsuransiId) {
        if (store.state[modules].priceRecognition === 0) {
          $toast.add({
            severity: 'error', detail: 'Biaya Pengakuan Harus diisi', group: 'bc', life: 3000
          })
          store.dispatch('hideLoading')
          showButton.value = false
          return
        }
      }
      await getGrandPrice()
      const formData = {
        TransPengirimanHd: {
          Id: store.state[modules].dataAfterPost.Id,
          JenisPembayaranId: store.state[modules].paymentId,
          GrandTotal: store.state[modules].grandPrice,
          BiayaTambahan: store.state[modules].biayaTambahanResi,
          DiskonPelanggan: store.state[modules].diskonPelangganCalculate ?? 0,
          TipePengiriman: store.state[modules].typeDelivery,
          KeteranganBiayaTambahan: store.state[modules].descriptionAddOn,
          PengakuanIsiBarang: store.state[modules].additionalFeeDesc,
          KeteranganPembayaran: store.state[modules].paymentRef,
          PengakuanNilaiBarang: store.state[modules].priceRecognition,
          TotalAsuransi: store.state[modules].costInsurance,
        },
        // Diskons: store.state[modules].listDiskon,
        Vouchers: store.state[modules].voucherId ? [{
          Kode: store.state[modules].voucherAttr.kodeVoucher,
          VoucherCodeId: store.state[modules].voucherAttr.voucherCodeId,
          VoucherHdId: store.state[modules].voucherAttr.voucherHdId,
          VoucherValue: store.state[modules].voucherAttr.voucherValue
        }] : [],
        Stikers: store.state[modules].itemsSticker.map((item: any) => ({
          Id: item.stickerId,
          Stock: item.value
        })),
        Labels: store.state[modules].itemsLabel.map((item: any) => ({
          Id: item.value
        }))
      }
      console.log('formData', formData)
      const response = await deliveryReceiptUsecase.submitReceipt(formData)
      if (addonReceiptEl.value.isHaveInsurance) {
        await addonReceiptEl.value.proccessUploadFile(formData)
      }
      if (!response.error && response.result.Resi) {
        showButton.value = false
        $toast.add({
          severity: 'success', detail: 'Resi berhasil diproses', group: 'bc', life: 5000
        })
        // if (typeDelivery !== 'AA') {
        //   deliveryReceiptUsecase.submitTransUserPengirimans({
        //     TransPengirimanHdId: store.state[modules].dataAfterPost.Id,
        //     TipePengiriman: typeDelivery
        //   })
        // }
        const encryptData = encryptDecriptMethods.encrypt(store.state[modules].dataAfterPost.Id)
        const encryptDataUser = encryptDecriptMethods.encrypt(store.getters['appActiveUser/getAppActiveUser']?.id)
        // console.log('dataAfterPost', store.state[modules].dataAfterPost)
        store.dispatch(`${modules}/setDataLastReceipt`, encryptData)
        if (isDownloadFile.value) {
          const result = await reportUseCase.getPdfResi(store.state[modules].dataAfterPost.Id, store.getters['appActiveUser/getAppActiveUser']?.id)
          // response.result.Resi
          const name = 'Resi.pdf'
          let notif = null
          if (await isMobile()) {
            const file = await toBase64(new Blob([result.result]))
            await fileWrite(name, file)
            // console.log('deliveryReceiptComposable download via android')
            notif = 'Resi berhasil diunduh ke folder : DOCUMENTS'
            $toast.add({
              severity: 'success', detail: notif, group: 'bc', life: 3000
            })
          } else {
            const fileURL = window.URL.createObjectURL(new Blob([result.result]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', name)
            document.body.appendChild(fileLink)
            await fileLink.click()
            // console.log('deliveryReceiptComposable download via web')
          }
          store.dispatch('hideLoading')
          router.push({ name: 'transaksi-pengiriman-barang' })
        } else {
          const routeData = router.resolve({ name: 'report', params: { Id: encryptData, jenisReport: 'resi', idUser: encryptDataUser } })
          window.open(routeData.href, '_blank')
          router.push({ name: 'transaksi-pengiriman-barang' })
        }
      } else {
        store.dispatch('hideLoading')
        showButton.value = false
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      }
    }
    const submitData = async () => {
      if (store.state[modules].itemsLabel.length === 0) {
        $toast.add({
          severity: 'error', detail: 'Label harus sesuai', group: 'bc', life: 3000
        })
      } else {
        refDialogConfirm.value?.openModal({
          imgContent: require('@/assets/img/confirmation/option-print-resi.png'),
          btnBackTitle: 'Tampilkan Resi',
          btnSubmitTitle: 'Download Resi',
          subTitle: '',
          title: 'Silakan pilih opsi untuk cetak resi!',
          isMiisi: isMiisi.value
        }).then((isConfirm) => {
          if (isConfirm) {
            isDownloadFile.value = true
          } else {
            isDownloadFile.value = false
          }
          prosesSubmit()
        })
      }
    }
    const submitBack = () => {
      $confirm.require({
        header: 'Kembali ke step 3',
        message: 'Voucher yang sudah anda gunakan akan di reset dan bisa anda gunakan lagi nanti, lanjutkan ?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch(`${modules}/setCurrentIndex`, 2)
          store.dispatch(`${modules}/setVoucherId`, '')
          store.dispatch(`${modules}/setPriceRecognition`, 0)
          store.dispatch(`${modules}/setCostInsurance`, 0)
          store.dispatch(`${modules}/setListDiskon`, [])
          store.dispatch(`${modules}/setListItem`, store.state[modules].listItems.map((item: any) => {
            item.DiskonId = null
            item.DiskonValue = 0
            return item
          }))
          store.dispatch(`${modules}/calculateGrandPrice`)
          // store.dispatch(`${modules}/setPaymentId`, null)
          store.dispatch(`${modules}/setDefaultAttrVoucher`)
        }
      })
    }

    watch(paymentRef, (val) => {
      store.dispatch(`${modules}/setPaymentRef`, val)
    })

    network.addListener('networkStatusChange', (status) => {
      console.log('status', status)
      isOnline.value = status.connected
      console.log(isOnline.value)
      if (status.connected) {
        // paymentId.value = 16
        window.location.reload()
      }
    })

    onMounted(() => {
      getListPayment()
      // console.log('takaeda', addonReceiptEl.value)
      paymentRef.value = store.state[modules].paymentRef
      console.log('state', store.state)
    })
    return {
      grandPrice,
      paymentId,
      paymentRef,
      listPayment,
      submitData,
      submitBack,
      getGrandPrice,
      getTextTypeDelivery,
      onChangePayment,
      showButton,
      addonReceiptEl,
      elListItemsReceipt,
      isMiisi,
      refDialogConfirm,
      isOnline,
      showPaymentOptions
    }
  }
}
