import Stepper from '@/views/transactions/delivery-item/create/Stepper.vue'
import DeliveryDestination from '@/views/transactions/delivery-item/create/steps-1/DeliveryDestination.vue'
import DeliveryListItem from '@/views/transactions/delivery-item/create/steps-3/DeliveryListItem.vue'
import DeliveryReceipt from '@/views/transactions/delivery-item/create/steps-4/DeliveryReceipt.vue'
import SenderData from '@/views/transactions/delivery-item/create/steps-2/SenderData.vue'
import {
  ref, onMounted, onBeforeUnmount, computed, getCurrentInstance
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  cancelPackageUseCase,
  draftItemUseCase,
  senderDataUseCase,
  receiveItemUseCase
} from '@/domain/usecase'
import { TypeCustomer } from '@/utils/enum/typeCustomer'
import { IMsAddress } from '@/utils/interfaces/iMsAddress'
import { isEmpty } from 'lodash'

const modules = 'deliveryItem'

export default {
  components: {
    Stepper,
    DeliveryDestination,
    DeliveryListItem,
    SenderData,
    DeliveryReceipt
  },
  setup() {
    const store = useStore()
    const app = getCurrentInstance()
    const currentIndex = computed(() => store.state[modules].currentIndex)
    const isMiisi = computed(() => store.state[modules].isMiisi)
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const route = useRoute()
    const router = useRouter()

    const submitData = () => {
      store.dispatch(`${modules}/setCurrentIndex`, store.state[modules].currentIndex + 1)
    }

    const submitBack = () => {
      store.dispatch(`${modules}/setCurrentIndex`, store.state[modules].currentIndex - 1)
    }

    const checkType = () => {
      const { type } = route.params
      if (type) {
        if (type === 'agent-to-door') {
          store.dispatch(`${modules}/setTypeDelivery`, 'AD')
        } else if (type === 'agent-to-agent') {
          store.dispatch(`${modules}/setTypeDelivery`, 'AA')
        } else if (type === 'door-to-agent') {
          store.dispatch(`${modules}/setTypeDelivery`, 'DA')
        } else if (type === 'door-to-door') {
          store.dispatch(`${modules}/setTypeDelivery`, 'DD')
        } else {
          $toast.add({
            severity: 'error', detail: 'Url yang anda masukan tidak ditemukan', group: 'bc', life: 3000
          })
          router.replace({ name: 'dashboard' })
        }
      } else {
        $toast.add({
          severity: 'error', detail: 'Url yang anda masukan tidak ditemukan', group: 'bc', life: 3000
        })
        router.replace({ name: 'dashboard' })
      }
    }
    checkType()

    onBeforeUnmount(() => {
      store.dispatch(`${modules}/setDefaultState`)
    })

    const getProfileCustomer = (username: string) => {
      draftItemUseCase.getProfile(username)
        .then(({ error, result }) => {
          if (!error) {
            const {
              Id
            } = result
            store.dispatch(`${modules}/setCustomerId`, Id)
          }
        })
    }

    const findAddress = (items: IMsAddress[], address: string): IMsAddress | undefined => items.find((item: IMsAddress) => item.Alamat1 === address || item.Alamat2 === address)

    const prepareDataCompany = (username: string, addressSender: string, addressReceiver: string) => {
      store.dispatch(`${modules}/setCompanyId`, username)
      senderDataUseCase.getSelectCompanyAddress({
        companyId: username
      }).then(({ error, result }) => {
        if (!error) {
          if (addressSender) {
            const find = findAddress(result, addressSender)
            if (!isEmpty(find)) {
              store.dispatch(`${modules}/setCompanyAddressId`, find?.Id)
            }
          }

          if (addressReceiver) {
            const find = findAddress(result, addressReceiver)
            if (!isEmpty(find)) {
              store.dispatch(`${modules}/setCompanyReceiveAddressId`, find?.Id)
            }
          }
        }
      })
    }

    const getDetail = () => {
      receiveItemUseCase.getDetail(route.params.id as string)
        .then(({ error, result }) => {
          if (!error) {
            const {
              AgenTujuanId,
              TipeCustomer,
              NamaPenerima,
              AlamatPenerima,
              HpPenerima,
              AlamatPengirim,
              NamaPengirim,
              HpPengirim,
              KeteranganPenerima,
              Username,
              Id,
              TransPengirimanDetails
            } = result
            store.dispatch(`${modules}/setToAgentId`, AgenTujuanId)
            store.dispatch(`${modules}/setDataAfterPost`, {
              Id
            })
            let postionTab = 1
            switch (TipeCustomer.toLowerCase()) {
              case TypeCustomer[1]:
                prepareDataCompany(Username, AlamatPengirim, AlamatPenerima)
                postionTab = 2
                break
              case TypeCustomer[2]:
                getProfileCustomer(Username)
                postionTab = 3
                break
              default:
                postionTab = 1
                break
            }
            store.dispatch(`${modules}/changePostionTabs`, postionTab)
            store.dispatch(`${modules}/setDataSender`, {
              namaPengirim: NamaPengirim,
              alamatPengirim: AlamatPengirim,
              telpPengirim: HpPengirim,
              pickupScheduleId: null,
              usernamePengirim: Username
            })
            store.dispatch(`${modules}/setDataReceiver`, {
              namaPenerima: NamaPenerima,
              alamatPenerima: AlamatPenerima,
              telpPenerima: HpPenerima,
              notesPenerima: KeteranganPenerima
            })
            // store.dispatch(`${modules}/setIsMiisi`, false)
            if (TransPengirimanDetails.length > 0) {
              store.dispatch(`${modules}/setListItem`, TransPengirimanDetails.map((item: any) => {
                item.TotalHarga = Number(item?.AsuransiValue) + Number(item.BiayaTambahan) + Number(item.Harga)
                item.KategoriBarang = item.KategoriBarangNama
                item.Satuan = item.KategoriBarangSatuanNama
                return item
              }))
            }
          }
        })
    }

    onMounted(() => {
      if (route.params.id && isEmpty(store.state[modules]?.dataAfterPost)) {
        getDetail()
      }
    })

    return {
      route,
      currentIndex,
      submitData,
      submitBack,
      isMiisi
    }
  }
}
