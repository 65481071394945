
import {
  ref,
  onMounted,
  getCurrentInstance,
  computed,
  watch
} from 'vue'
import {
  useStore
} from 'vuex'

const modules = 'deliveryItem'

export default {
  name: 'TabsDay',
  emits: ['tabsChange'],
  setup(props: any, {
    emit
  }: any) {
    const store = useStore()
    const active = computed({
      get: () => store.state[modules].tabsSchedule.key,
      set: (val) => {
        // console.log('ini value compo', val)
        emit('tabsChange', val)
      }
    })
    const watchActive = ref(active)

    const arrHeader = ref([{
      params: 'HariIni',
      key: 0
    }, {
      params: 'Besok',
      key: 1
    }, {
      params: 'Lusa',
      key: 2
    }])

    // watch(watchActive.value, (newVal, oldVal) => {
    //   console.log('ini val', newVal)
    //   if (newVal !== oldVal) {
    //   }
    // })

    return {
      active,
      arrHeader,
      watchActive
    }
  }
}
