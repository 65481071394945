import {
  ref, onMounted, computed, watch, getCurrentInstance, reactive
} from 'vue'
import {
  deliveryReceiptUsecase,
  labelUseCase,
  receiveItemFileUseCase,
  labelAgentUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
// start add image camera=============================================================
import { compressImage } from '@/plugins/compressor'
import { useDropzone } from 'vue3-dropzone'
import { takePhoto } from '@/plugins/system/camera'
// end add image camera=============================================================
import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'

const modules = 'deliveryItem'
const appActiveUser = 'appActiveUser'

export default {
  name: 'AddOnReceipt',
  components: {
    VueEternalLoading
  },
  setup() {
    const store = useStore()
    const app = getCurrentInstance()
    const { $toast } = app!.appContext.config.globalProperties
    const stickerOption = ref([]) as any
    const stickerOptionDefault = ref([]) as any
    const sticker = ref(null)
    const sourceSearch = ref(null) as any
    const isLoadingLabel = ref(false)
    const labelInput = ref('') as any
    const op = ref()
    const isDragActive = ref(false)
    const filtersLabel = reactive({
      search: ''
    })
    const pageNumber = ref(1)
    const tempLengthOfList = ref(0)
    const profileAgent = store.getters[`${appActiveUser}/getAgent`]
    const isMiisi = computed(() => store.state[modules].isMiisi)
    let action: LoadAction
    const additionalFeeDesc = computed({
      get: () => store.state[modules].additionalFeeDesc,
      set: (val) => {
        store.dispatch(`${modules}/setAdditionalFeeDesc`, val)
      }
    })
    const additionalCost = computed({
      get: () => store.state[modules].biayaTambahanResi,
      set: (val) => {
        store.dispatch(`${modules}/setBiayaTambahan`, val)
        store.dispatch(`${modules}/calculateGrandPrice`)
        // store.dispatch(`${modules}/calculateTotalDiskon`, val)
      }
    })
    const description = computed({
      get: () => store.state[modules].descriptionAddOn,
      set: (val) => {
        store.dispatch(`${modules}/setDescriptionAddOn`, val)
      }
    })
    const dataDiskon = computed({
      get: () => store.state[modules].diskonPelanggan,
      set: (val) => {
        store.dispatch(`${modules}/setDiskonPelanggan`, val)
      }
    })
    const customerDiscount = computed(() => `Rp. ${Number(store.state[modules].diskonPelangganCalculate).toLocaleString('id-ID')}`)
    const label = computed({
      get: () => store.state[modules].itemsLabel,
      set: (val) => {
        store.dispatch(`${modules}/setitemsLabel`, val)
      }
    }) as any
    const calculateIsurance = (price: number) => {
      let premiValue = 0
      if (store.state[modules].listItems.length > 0) {
        if (store.state[modules].listItems[0].AsuransiId) {
          premiValue = store.state[modules].listItems[0].AsuransiPremi
        }
      }
      return price * (premiValue / 100)
    }
    const priceRecognition = computed({
      get: () => store.state[modules].priceRecognition,
      set: (val) => {
        store.dispatch(`${modules}/setPriceRecognition`, val)
        store.dispatch(`${modules}/setCostInsurance`, calculateIsurance(val))
        store.dispatch(`${modules}/calculateGrandPrice`)
      }
    })
    const costInsurance = computed({
      get: () => store.state[modules].costInsurance,
      set: (val) => {
        store.dispatch(`${modules}/setCostInsurance`, val)
        store.dispatch('deliveryItem/calculateGrandPrice')
        // store.dispatch(`${modules}/calculateTotalDiskon`, val)
      }
    })
    const costInsuranceText = computed(() => `Rp. ${Number(store.state[modules].costInsurance).toLocaleString('id-ID')}`)
    const labelOption = ref([]) as any
    const totalHarga = ref(0)
    const labelIdSelect = ref(null) as any
    const groupLabel = ref([])
    const isInitial = ref(false)/** variable yang digunakan untuk mereset state menggunakan plugin vue-eternal-loading */

    const listInput = computed(() => store.state[modules].itemsSticker)
    // const isDontHaveInsurance = computed(() => priceRecognition.value === 0)
    const isHaveInsurance = computed(() => store.state[modules].listItems.length > 0 && store.state[modules].listItems[0].AsuransiId)
    const dataItemsSticker = ref(listInput)

    const lookForDiskon = (param: any = null) => {
      // console.log('param', param)
      // console.log('param', param.data.result.Diskon)
      // console.log('modules', store.state[modules])
      let getDiskon = true
      // console.log('listItems', store.state[modules].listItems)
      // store.state[modules].listItems.forEach((element: any) => {
      //   if (element.GetDiskon === false) {
      //     getDiskon = false
      //   }
      // })
      if (store.state[modules].companyId !== 0) {
        if (store.state[modules].diskonPelanggan <= 0) {
          getDiskon = false
        }
      } else if (store.state[modules].dataAfterPost.PelangganId !== null) {
        // if (param.data.result.Diskon <= 0) {
        if (store.state[modules].diskonPelanggan <= 0) {
          getDiskon = false
        }
      } else {
        getDiskon = false
      }
      // if (store.state[modules].diskonPelanggan <= 0 || param.data.result.Diskon <= 0) {
      //   getDiskon = false
      // }
      // console.log('diskon', getDiskon)
      return getDiskon
    }
    const fetchCustomerRetailSpecific = async () => {
      // console.log('retail')
      // const result = await customerRetailUseCase.getDataForm(store.state[modules].dataAfterPost.PelangganId)
      // console.log('result', result)
      // if (lookForDiskon(result) === true) {
      if (lookForDiskon() === true) {
        dataDiskon.value = store.state[modules].diskonPelanggan
      } else {
        dataDiskon.value = 0
      }
      await store.dispatch(`${modules}/calculateTotalDiskon`)
      store.dispatch(`${modules}/calculateGrandPrice`)
    }

    const fetchCustomerCompanySpecific = async () => {
      // console.log('company')
      // console.log('state', store.state[modules])
      // const result = await customerCompanyUseCase.getDataForm(store.state[modules].dataAfterPost.CompanyId)
      // console.log('res', store.state[modules].dataAfterPost)
      if (lookForDiskon() === true) {
        dataDiskon.value = store.state[modules].diskonPelanggan
      } else {
        dataDiskon.value = 0
      }
      await store.dispatch(`${modules}/calculateTotalDiskon`)
      store.dispatch(`${modules}/calculateGrandPrice`)
    }

    const getColorLabel = async () => {
      const result = await labelUseCase.getAll({
        sorting: 'Kode asc',
        select: '&$select=Id,Kode,Nama,Warna'
      })

      groupLabel.value = result.result.Data.map((x: any) => ({
        color: x.Warna,
        label: x.Nama,
        value: x.Id
      }))
    }

    const getColorLabelV2 = async () => {
      filtersLabel.search = profileAgent.id
      const result = await labelUseCase.getAll({ ...filtersLabel, filterField: ['agenId'] })

      groupLabel.value = result.result.Data.map((x: any) => ({
        color: x.Warna,
        label: x.Nama,
        value: x.Id
      }))
    }

    const mappingLabel = (labels: Array<any>) => {
      const arrLabel = [] as any
      labels.forEach((values: any) => {
        let hasValue = false
        for (let i = 0; i < arrLabel.length; i++) {
          const item = arrLabel[i]
          if (item.value === values.LabelId) {
            hasValue = true
            break
          }
        }
        if (!hasValue) {
          arrLabel.push({
            color: values.Label.Warna,
            label: values.Label.Nama,
            value: values.Label.Id
          })
        }
      })
      groupLabel.value = arrLabel
    }

    const fetchLabel = async (search: any = null) => {
      isLoadingLabel.value = true
      const result = await deliveryReceiptUsecase.getSelectDataLabel({
        filter: `AgenId eq ${profileAgent.id} and LabelId eq ${labelIdSelect.value} and IsPicked eq false and TransPengirimanHdId eq null`,
        sorting: 'KodeLabel asc',
        expand: '&$expand=Label($select=Id,Kode,Warna)',
        /* eslint-disable-next-line object-shorthand */
        search: search
      })
      // mappingLabel(result.result)
      if (!result.error) {
        labelOption.value = await result.result.map((x: any) => ({
          color: (x.Label) ? x.Label.Warna : 'grey',
          label: x.KodeLabel,
          value: x.Id,
          labelId: x.LabelId
        }))
      }
      isLoadingLabel.value = false
    }

    const fetchLabelV2 = async (search: any = '') => {
      isLoadingLabel.value = true
      filtersLabel.search = search === '' ? search : sourceSearch.value
      const result = await labelAgentUseCase.getStockLabelAgent(profileAgent.id, { ...filtersLabel, filterField: ['kodeLabel'], custom: [['isPicked', '=', 'false'], ['labelId', '=', labelIdSelect.value], ['pageNumber', '=', pageNumber.value], ['orderBy', '=', 'createdAt desc, kodeLabel asc']] })
      tempLengthOfList.value = result.result.Data.length

      if (!result.error) {
        const labelOptionTemp = await result.result.Data.map((x: any) => ({
          color: (x.LabelWarna) ? x.LabelWarna : 'grey',
          label: x.KodeLabel,
          value: x.Id,
          labelId: x.LabelId
        }))
        labelOptionTemp.forEach((x: any) => labelOption.value.push(x))
      }

      isLoadingLabel.value = false
    }

    const searchStockLabel = async (val: any) => {
      pageNumber.value = 1
      if (val) {
        sourceSearch.value = val
        if (sourceSearch.value >= 2) {
          // await fetchLabel(sourceSearch.value)
          await fetchLabelV2(sourceSearch.value)
        }
      }
    }

    const searchBtnLabel = async (evt: any) => {
      pageNumber.value = 1
      sourceSearch.value = labelInput.value
      if (labelIdSelect.value && sourceSearch.value) {
        // await fetchLabel(sourceSearch.value)
        await fetchLabelV2(sourceSearch.value)
        await op.value.toggle(evt)
      }
    }

    const changeValueLabelInput = async (val: any, evt: any) => {
      labelOption.value = []
      pageNumber.value = 1
      await op.value.toggle(evt)
      // await op.value.show()
      isInitial.value = await true
      sourceSearch.value = await val
      // labelOption.value = []
      // await fetchLabel(sourceSearch.value)
      await fetchLabelV2(sourceSearch.value)
    }

    const clickPlace = async (val: any) => {
      if (label.value.length < store.state[modules].listItems.length) {
        if (label.value.map((x: any) => x.value).indexOf(val.value) > -1) {
          $toast.add({
            severity: 'error', summary: 'Label Tidak Boleh Sama', detail: `Label ${val.label} Telah Digunakan`, group: 'bc', life: 3000
          })
        } else {
          await label.value.push(val)
        }
      } else {
        $toast.add({
          severity: 'error', detail: `Pilhan Label Maksimal ${store.state[modules].listItems.length}`, group: 'bc', life: 3000
        })
      }
    }

    const deleteLabel = async (val: any) => {
      label.value.splice(val, 1)
    }

    const stickerFilter = () => {
      const dataMap = store.state[modules].itemsSticker.map((x: any) => x.stickerId)
      const data = stickerOptionDefault.value.filter((item: any) => !dataMap.includes(item.value))
      stickerOption.value = data
    }

    const fetchSticker = async () => {
      const result = await deliveryReceiptUsecase.getSelectDataSticker(profileAgent.id)
      stickerOption.value = result.result.map((x: any) => ({
        label: `${x.Sticker.Kode} ${x.Sticker.Nama}`,
        value: x.Id
      }))
      stickerOptionDefault.value = stickerOption.value
      stickerFilter()
    }

    const tambahSticker = () => {
      dataItemsSticker.value.push({
        stickerId: 0,
        value: 0,
        stickerOption: stickerOption.value
      })
    }

    const deleteSticker = (index: any) => {
      dataItemsSticker.value.splice(index, 1)
    }

    // start image camera=============================================================
    const listImage = ref([]) as any
    const checkSrcListImage = () => listImage.value.filter((image: any) => image.src === null).length

    const generateListImage = () => {
      for (let index = 0; index < 5; index++) {
        listImage.value.push({
          name: 'add-image',
          src: null,
          image: null
        })
      }
    }

    const pushListImage = async (item: any, from: any = '') => {
      let file = item
      if (from === 'camera') {
        // file = new File([item], 'Name', {
        //   lastModified: moment().unix(),
        //   type: item.type,
        // })
        item = item.image
      } else {
        // eslint-disable-next-line no-await-in-loop
        file = await compressImage(file)
        file = new File([file], file.name)
        // console.log('file to compress', file)
      }
      for (let index = 0; index < listImage.value.length; index++) {
        const image = listImage.value[index]
        if (image.src === null) {
          listImage.value[index].src = URL.createObjectURL(item)
          listImage.value[index].image = file
          break
        }
      }
    }

    const removeImage = (index: number) => {
      listImage.value[index].src = null
      listImage.value[index].image = null
    }

    const resetImage = () => {
      for (let index = 0; index < listImage.value.length; index++) {
        listImage.value[index].src = null
        listImage.value[index].image = null
      }
    }

    function onDrop(acceptFiles: any, rejectReasons: any) {
      if (checkSrcListImage() >= acceptFiles.length) {
        for (let index = 0; index < acceptFiles.length; index++) {
          if (acceptFiles[index].type.includes('image')) {
            pushListImage(acceptFiles[index])
          } else {
            $toast.add({
              severity: 'error', detail: 'Hanya support tipe file gambar (png/jpg/jpeg)', group: 'bc', life: 1500
            })
          }
        }
      } else {
        $toast.add({
          severity: 'error', detail: 'Maksimal upload gambar sebanyak 5', group: 'bc', life: 1500
        })
      }
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop })
    const showCamera = async () => {
      const response = await takePhoto() as any
      // console.log('response', JSON.stringify(response))
      pushListImage(response, 'camera')
    }

    const proccessUploadFile = async (receive: any) => {
      const listPromise = [] as any
      // console.log('proccessUpoad', JSON.stringify(listImage.value))
      if (listImage.value.length > 0) {
        // console.log('wowo masuk nih')
        for (let index = 0; index < listImage.value.length; index++) {
          const item = listImage.value[index]
          if (item.src !== null) {
            const formData = new FormData()
            // const senderId = props.id
            const senderId = receive.TransPengirimanHd.Id
            const img = item.image
            // formData.append('PengirimanId', senderId)
            // formData.append('IsPenerimaan', 'false')
            const sendData = {
              IsPenerimaan: false,
              PengirimanId: senderId
            } as any
            if (img.from === 'camera') {
              // console.log('if on camera')
              formData.append('file', img.image, `${img.name}.${img.ext}`)
            } else {
              // console.log('img', img)
              formData.append('file', img)
            }
            // listPromise.push(receiveItemFileUseCase.submitData(receive.TransPengirimanHd.Id, formData))
            receiveItemFileUseCase.submitFile('pengiriman&useid=true', formData).then((res) => {
              // console.log('hasil upload res', res)
              sendData.Filename = res.result.Filename
              sendData.Thumbnail = res.result.Thumbnail
              listPromise.push(receiveItemFileUseCase.submitDataPenerimaanFiles(null, sendData))
            })

            // listPromise.push(receiveItemFileUseCase.submitFile(formData))
          }
        }
      }

      await receiveItemFileUseCase.submitDataAll(listPromise).then((responses) => {
        $toast.add({
          severity: 'success', detail: 'Berhasil upload gambar', group: 'bc', life: 1500
        })
        // hideForm()
        // emit('successBack')
        // router.push({ name: 'transaksi-barang-diterima' })
      }).catch(() => {
        $toast.add({
          severity: 'error', detail: 'Gagal upload gambar, silahkan coba kembali', group: 'bc', life: 1500
        })
      })
    }
    // end add image camera=============================================================

    onMounted(() => {
      if (store.state[modules].companyId !== 0) {
        fetchCustomerCompanySpecific()
      } else if (store.state[modules].dataAfterPost.PelangganId !== null) {
        fetchCustomerRetailSpecific()
      } else {
        dataDiskon.value = 0
        store.dispatch(`${modules}/calculateTotalDiskon`)
      }
      // fetchLabel()
      // getColorLabel()
      getColorLabelV2()
      fetchSticker()
      // tambahSticker()

      generateListImage()
    })

    /** Menggunakan load data untuk plugin pagination */
    const loadData = async ({ loaded, noMore }: LoadAction) => {
      if (tempLengthOfList.value >= 10) {
        pageNumber.value += 1
        fetchLabelV2().then((x: any) => {
          if (tempLengthOfList.value < 10) {
            /** menggunakan fungsi unutuk mereset state data kosong type dri interface plugin vue-eternal-loading */
            noMore()
          } else {
            /** menggunakan fungsi unutuk load state type dri interface plugin vue-eternal-loading */
            loaded()
          }
        })
      } else {
        noMore()
      }
    }

    watch([dataItemsSticker.value], ([newVal2], [oldVal2]) => {
      // store.dispatch(`${modules}/setBiayaTambahan`, newVal)
      // store.dispatch(`${modules}/calculateTotalDiskon`, newVal)
      if (newVal2) {
        store.dispatch(`${modules}/setItemsSticker`, newVal2)
        stickerFilter()
      }
    })

    return {
      additionalCost,
      customerDiscount,
      store,
      labelOption,
      label,
      totalHarga,
      dataDiskon,
      labelIdSelect,
      groupLabel,
      fetchSticker,
      stickerOption,
      sticker,
      listInput,
      tambahSticker,
      dataItemsSticker,
      deleteSticker,
      stickerFilter,
      description,
      priceRecognition,
      costInsurance,
      costInsuranceText,
      isHaveInsurance,
      isLoadingLabel,
      searchStockLabel,
      searchBtnLabel,
      labelInput,
      changeValueLabelInput,
      op,
      clickPlace,
      deleteLabel,
      // start camera
      listImage,
      checkSrcListImage,
      resetImage,
      showCamera,
      getRootProps,
      getInputProps,
      proccessUploadFile,
      additionalFeeDesc,
      isDragActive,
      loadData,
      isInitial,
      isMiisi
      // end camera
    }
  }
}
